import React from 'react';
import './Footer.css'; // Import your CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faHeart } from '@fortawesome/free-solid-svg-icons'

function Footer() {
  return (
    <footer className="transparent-footer">
      <p>
        <FontAwesomeIcon icon={faCode} /> with{' '}
        <FontAwesomeIcon icon={faHeart} /> by Arthur Corbesier
      </p>
    </footer>
  );
}

export default Footer;
