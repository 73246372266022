import React from "react";
import './about.css';
import ME from './me-about.jpg';
import CV from './CV Arthur Corbesier.pdf'

const About = () => {
    return (
        <section id='about'>
            <h5>Get to know</h5>
            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ME} alt="About Image" />
                    </div>
                </div>
                <div className="about__content">
                    <div className="about__cards">
                        <article className="about__card">
                            {/* Placeholder for Experience icon */}
                            <div className="about__icon-placeholder"></div>
                            <h5>Experience</h5>
                            <small>2022 - Present</small>
                        </article>

                        <article className="about__card">
                            {/* Placeholder for Clients icon */}
                            <div className="about__icon-placeholder"></div>
                            <h5>Clients</h5>
                            <small>/ Worldwide.</small>
                        </article>

                        <article className="about__card">
                            {/* Placeholder for Projects icon */}
                            <div className="about__icon-placeholder"></div>
                            <h5>Projects</h5>
                            <small>4 Completed.</small>
                        </article>
                    </div>

                    <p>
                        Belgian coder with a passion for programming, learning, and problem-solving. Always seeking new ways to make things easier and more efficient. With a wide range of coding experience from web development to C# projects, I'm constantly seeking out new opportunities to expand my knowledge and hone my skills. I'm committed to writing elegant, scalable, and bug-free code that meets the needs of users. I'm also passionate about sharing my knowledge and helping others learn to code. With a focus on quality and innovation, I look forward to every new challenge.
                    </p>

                    <a href={CV} download className='btn'>Download CV</a>
                </div>
            </div>
        </section>
    )
}

export default About;
