import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        "&:hover text": {
            fill: theme.palette.primary.main,
        },
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="15 5 204 342"
            className={classes.svgHover}
        >
            <text
                x="50"
                y="250"
                fontSize="200"
                fontFamily="Arial"
                fontWeight="bold"
                fill="currentColor"
                stroke="none"
            >
                A
            </text>
        </svg>
    );
};
