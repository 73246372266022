import React from 'react';
import { LogoLink } from '../components/logo/LogoLink';
import { Content } from '../components/content/Content';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisplacementSphere from '../components/background/DisplacementSphere';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';

import About from './about/About.jsx'; // Import the About component
import Portfolio from './portfolio/Portfolio.jsx'; // Import the Portfolio component
import Experience from './experience/Experience.jsx'; // Import the Experience component
import Contact from './contact/Contact.jsx'; // Import the Contact component
import Footer from './footer/Footer.jsx'; // Import the Contact component


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center content vertically
    alignItems: 'center', // Center content horizontally
    minHeight: '90vh',
    overflowY: 'scroll', // Enable vertical scrolling
  },
  contentSection: {
    marginTop: '35vh',
    marginBottom: '12vh',
  },
  section: {
  },
  aboutSection: {
    marginBottom: '15vh', // Each section takes up the full viewport height
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  experienceSection: {
    marginBottom: '15vh', // Adjust the margin as needed
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  portfolioSection: {
    marginBottom: '10vh', // Each section takes up the full viewport height
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contactSection: {   
  marginBottom: '15vh', // Each section takes up the full viewport height
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  },
  footerSection: {   
  marginBottom: 'vh', // Each section takes up the full viewport height
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  },
}));

export const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DisplacementSphere />
      <LogoLink />
      {/* Contact Section */}
      <section className={`${classes.section} ${classes.contentSection}`}>
        <Content />
      </section>
      {/* About Section */}
      <section className={`${classes.section} ${classes.aboutSection}`}>
        <About /> {/* Use the About component */}
      </section>
      {/* Experience Section */}
      <section className={`${classes.section} ${classes.experienceSection}`}>
        <Experience /> {/* Use the Experience component */}
      </section>
      {/* Portfolio Section */}
      <section className={`${classes.section} ${classes.portfolioSection}`}>
        <Portfolio /> {/* Use the Portfolio component */}
      </section>
      {/* Contact Section */}
      <section className={`${classes.section} ${classes.contactSection}`}>
        <Contact /> {/* Use the Contact component */}
      </section>
      <section className={`${classes.section} ${classes.footerSection}`}>
        <Footer /> {/* Use the Contact component */}
      </section>
      <Hidden smDown>
        <SocialIcons />
      </Hidden>
      <Hidden mdUp>
        <SpeedDials />
      </Hidden>
    </div>
  );  
};