import React from 'react';
import './Portfolio.css';

const Portfolio = () => {
  return (
    <>
  <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Cards 01</title>
  <link rel="stylesheet" href="/style.css" />
  <section id='experience'>
        <h5>My recent work</h5>
        <h2>Portfolio</h2>
  </section>
  <div className="card-group">
    <article className="card">
      <header className="card-header">
        <span className="card-date">
          <strong> Published </strong>
          on Sept 19, 2023
        </span>
        <h3 className="card-title">
          <a href="#" className="card-title">
            Portfolio finished
          </a>
        </h3>
      <p>Today, I'm thrilled to announce the completion of my portfolio. I hope you all enjoy it. If you have any questions or encounter any issues, please don't hesitate to contact me!</p>
      </header>
      <footer className="card-footer">
        <div className="card-image">
        <img src="android-chrome-192x192.png" alt="Portfolio Image" />
        </div>
        <h4 className="card-author">Arthur Corbesier</h4>
      </footer>
    </article>
        <article className="card">
      <header className="card-header">
        <span className="card-date">
          <strong> Published </strong>
          on Jan 2, 2024
        </span>
        <h3 className="card-title">
          <a href="#" className="card-title">
            First game 
          </a>
        </h3>
      <p>Today, I finished my first game! It's a basic infinite runner. https://runner.avac.dev/</p>
      </header>
      <footer className="card-footer">
        <div className="card-image">
        <img src="android-chrome-192x192.png" alt="Portfolio Image" />
        </div>
        <h4 className="card-author">Arthur Corbesier</h4>
      </footer>
    </article>
    <article className="card">
      <header className="card-header">
        <span className="card-date">
          <strong> Published </strong>
          on Jan 3, 2024
        </span>
        <h3 className="card-title">
          <a href="#" className="card-title">
            Fight game 
          </a>
        </h3>
      <p>Today, I finished my second game! It's a 1v1 fight game. https://fight.avac.dev/</p>
      </header>
      <footer className="card-footer">
        <div className="card-image">
        <img src="android-chrome-192x192.png" alt="Portfolio Image" />
        </div>
        <h4 className="card-author">Arthur Corbesier</h4>
      </footer>
    </article>
  </div>
</>
  );
};

export default Portfolio;
